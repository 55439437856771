module.exports.password = (function() {
    var object;

    if(! ($ && typeof $ == "function")) {
        return false;
    }

    object = {

        displayRawFrom : function (inputPasswordId) {
            object.inputPassword = $(inputPasswordId);
            return object;

        },

        whenClickOn : function (actionElementId) {

            $(actionElementId).click(function () {

                $(this).toggleClass('shown');
                var typeProp;

                if ($(this).hasClass('shown')) {
                    typeProp = 'text';
                } else {
                    typeProp = 'password';
                }

                object.inputPassword.prop('type', typeProp);


            });


            return object;
        },

        confirm: function () {
            _log("confirm")
        },

        handleShow : function () {
            $('.show-password-action').click(function () {
                $(this).toggleClass('shown');
                var typeProp;

                if ($(this).hasClass('shown')) {
                    typeProp = 'text';
                } else {
                    typeProp = 'password';
                }

                $($(this).data('input')).prop('type', typeProp);
            });
        },

        generate: function () {
            var g = '';

            $.each(_rules(), function (k, v) {
                var m = v.max;
                for (var i = 1; i <= m; i++) {
                    g = g + v.characters[Math.floor(Math.random() * (v.characters.length))];
                }
                if (v.callback) {
                    g = v.callback(g);
                }
            });
            return g;
        }
    };

    return object;

})();

function _log(message) {
    console.log(message)
}

function _rules() {
    return [

        //Take a combination of 12 letters and numbers, both lower and upper case.
        {
            characters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890',
            max: 12
        },

        //Take 4 special characters, use the callback to shuffle the resulting 16 character string
        {
            characters: '!@#$%^&*()_+|~-={}[]:";<>?,./',
            max: 4,
            callback: function (s) {
                var a = s.split(""),
                    n = a.length;

                for (var i = n - 1; i > 0; i--) {
                    var j = Math.floor(Math.random() * (i + 1));
                    var tmp = a[i];
                    a[i] = a[j];
                    a[j] = tmp;
                }
                return a.join("");
            }
        }
    ];
}